<template>
  <div class="contact">
    <div class="contactMain">
      <div class="view flex-row m-b-78 con" v-if="notPhone">
        <div class="wcc m-r-25 head-img p-b-10">
          <img class="width100 height100" :src="data.img_big"/>
        </div>
        
        <div class=" view justify-between flex-1">
          <div>
            <div class="f-title ">{{ data.title }}</div>
            <div class="sed-title" v-html="data.sub_title"></div>
            <div class="c_666 posit" v-html="data.position"></div>
          </div>
          
          <div class="t-desc" v-html="data.description">
            
          </div>
        </div>
      </div>

      <div class="view   con" v-else>
        <div class="wcc  head-img p-b-10">
          <img class="width100 height100" :src="data.img_big"/>
        </div>
        
        <div class=" view justify-between flex-1">
          <div>
            <div class="f-title ">{{ data.title }}</div>
            <div class="sed-title" v-html="data.sub_title"></div>
            <div class="c_666 posit" v-html="data.position"></div>
          </div>
          
          <div class="t-desc" v-html="data.description">
            
          </div>
        </div>
      </div>


      <div class="view flex-row ">
        <div class="wcc215 is787">&nbsp;&nbsp;</div>
        <div class="flex-1 c_000 m-b-60 l-h-ep" v-html="data.content">
        </div>
      </div>

       <div class="article mh134 b-t-1 p-t-34 p-b-10">
        <div class="view flex-row">
          <div class="m-r-15 fz-14 c000">相关文章</div>
          <div>
            <div v-if="articlelist.length > 0" :class="showMore ? 'hided':''" >
              <div class="fz-14 c000 pointer hore m-b-12" @click="open(item)" v-for="(item,index) in articlelist" :key="index" >{{ item.title }}</div>
            </div>
            <div v-else class="fz-14 c000">暂无</div>
            <div v-if="articlelist.length > 4" @click="showMore = !showMore" class=" text-center pointer m-t-10 fz-14">
              
              <span v-if="showMore" class="fz-14 c000">{{ $t("m.more") }}</span>
              <span v-else class="fz-14 c000">{{ $t("m.close") }}</span>
              <img :style="showMore ? 'padding-bottom:4px;':'transform: rotate(180deg);'" style="height: 22px;" src="@/assets/images/more.png" class="w18 h18 inline-block"/>
    
            </div>
            
          </div>
          
          
        </div>
        
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      articlelist: [],
      headdata: this.$t("m.onHeaddata"),
      data: "",
      notPhone: true,
      showMore: true,
    };
  },
  components: {},
 
  created() {
    if (window.innerWidth <= 768) {
      this.notPhone = false
    }
      this.getData();
    this.getArticles()
  },
  methods: {

    open(url) {
      window.open(url.link)
    },
    getArticles() {
      this.axios
        .get(
          this.GLOBAL.serverSrc + "/wc/userRelate?id=" + this.$route.params.id
        ) // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          if (res.data.code == 1) {
            this.articlelist = res.data.data
          }
        })
    },
    getData() {
      this.axios
        .get(
          this.GLOBAL.serverSrc + "/wc/memberDetail?id=" + this.$route.params.id
        ) // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          if (res.data.code == 1) {
            this.data = res.data.data;
            this.$nextTick( () => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
            })
            
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  height: 100%;
  width: 100%;
  .contactMain {
    
    width: 80%;
    margin:0 auto;
    padding-top: 140px;
    .leftBox {
      width: 350px;
      float: left;
      height: auto;
      padding: 0 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rightBox {
      width: auto;
      margin-left: 450px;
    }
    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
      font-weight: 800;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    
    .title_en {
      height: 19px;
      font-size: 20px;
      font-weight: 400;
      color: #0066BB;
      line-height: 20px;
    }
  }
}
.c_666 {color: #B3B3B3;}
.posit {margin-top:45px;font-size:20px;}
.wcc {width: 25%;}
.wcc215 {width: calc(25% + 15px);}
.f-title {

font-size: 34px;
color: #000;
font-weight: bold;
margin-bottom: 15px;
}
.sed-title {
  font-size: 20px;
  color: #0066BB;
  margin-bottom: 10px;
}

.t-desc {
color: #000;
}
.third-title {
  color: #B3B3B3;
  font-size: 20px;
}
.c_000 {color: #000;}

@media (max-width: 768px) {
  .l-h-ep p{font-size: 12px !important;}
  .con {margin-bottom:15px;}
  .contact .contactMain .leftBox {
    width: 100%;
    float: left;
    height: auto;
    padding: 0px;
  }
  .posit {margin-top:15px;font-size:14px;}
  .head-img {width: 100%;}
  .contact .contactMain {padding:10px !important;width:100% !important;}
  .is787 {display: none;}
  .f-title {

      font-size: 18px;
      color: #000;
      font-weight: bold;
      }
      .sed-title {
        font-size: 16px;
        color: #0066BB;
      }
      .t-desc {
      color: #000;
      font-size: 14px;

      }
      
  .contact .contactMain .rightBox {
    margin-left: 0px;
  }
}
.mh134 {min-height: 134px;}
</style>